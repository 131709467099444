<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>
          Quotation: #{{ quotation.jobNumber }}
          &nbsp;
          Customer ref: #{{ quotation.customerReference }}
          <span v-if="quotation.isRemedial">
            <span v-if="quotation.converted">(This remedial has been confirmed)</span>
          </span>
          <span v-else>
            <span v-if="quotation.converted">(This quotation has been converted)</span>
            <span v-if="quotation.lost">(This quotation has been lost)</span>
            <span v-if="quotation.proforma">(This quotation is awaiting a proforma payment)</span>
          </span>
          <span class="float-right">Chase History</span>
        </h1>
        <p>Site: {{ quotation.site }}</p>
        <p>Sales person: {{ quotation.quotedBy }}</p>
        <div v-if="quotation.followUps">
          <p :key="index" v-for="(followUp, index) in quotation.followUps">
            #{{ (index + 1) }} email sent {{ followUp.created | prettyDateTime }}<br /><br />
            <span>{{ followUp.notes }}</span><br />
            <v-textarea label="Notes" v-model="followUp.newNotes"></v-textarea>
          </p>
          <p>
            <v-btn @click="saveNotes">Save notes</v-btn>
          </p>
        </div>
        <div v-else>
          <p>This quotation has not been followed up yet</p>
        </div>
      </v-col>
    </v-row>
    <v-dialog
        v-model="showMessage"
        max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="orange darken-2"
              text
              @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'ChaseHistoryPage',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      message: '',
      quotation: {
        jobNumber: '',
      },
      showMessage: false,
    };
  },
  methods: {
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
          .then((response) => {
            this.quotation = response.data;
          });
    },
    saveNotes() {
      axios.post(`/followUps/update/${this.$route.params.id}.json?token=${this.token}`, this.quotation)
          .then((response) => {
            this.message = response.data.message;
            this.showMessage = true;
          });
    },
  },
  mounted() {
    this.getQuotation();
  },
};
</script>
